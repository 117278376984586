<template>
	<div class="pay-page">
		<h2 class="pay-page__title">Способ оплаты</h2>
		<div class="pay-page__cards">
			<div
				class="pay-page__card"
				v-for="card in cards"
			>
				<div class="pay-page__card-info">
					<div class="pay-page__card-icon">
						<img
							:src="card.PAY_SYSTEM?.UF_IMAGE"
							alt="Pay"
						>
					</div>
					{{ convertUfCardNumber(card.UF_CARD_NUMBER) }}
				</div>
				<img
					@click="currentDeleteCard.info = card; currentDeleteCard.popup = true"
					class="pay-page__card-trash"
					src="~@/assets/img/trash.svg"
					alt="Trash"
				>
			</div>
			<a
				href="/api/personal/saveCard"
				class="pay-page__card"
			>
				<div class="pay-page__card-info">
					<div
						class="pay-page__card-icon"
						:style="{backgroundColor: 'white'}"
					>
						<PlusIcon/>
					</div>
					Добавить новую карту
				</div>
			</a>
		</div>
		<ConfirmationPopup
			:visible="currentDeleteCard.popup"
			:title="`Удалить карту ${lastFourNumbersCard}?`"
			subtitle="Чтобы сохранить карту снова, нужно при оплате заказа указать ее реквизиты"
			class="pay-page__delete-popup"
			@closePopup="currentDeleteCard.popup = false"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn"
					@click="handleDeleteCard"
				>
					Удалить
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {convertUfCardNumber} from "@/helpers";
import PlusIcon from "icons/16/plus.svg?inline";

export default {
	name: 'Pay',
	data()
	{
		return {
			currentDeleteCard: {
				popup: false,
				info: null
			}
		}
	},
	components: {
		ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup.vue"),
		PlusIcon
	},
	computed:
		{
			...mapGetters({
				cards: "personal/getCards"
			}),
			lastFourNumbersCard()
			{
				return this.currentDeleteCard.info?.UF_CARD_NUMBER?.slice(-4) || '****';
			}
		},
	methods:
		{
			convertUfCardNumber,
			...mapActions({
				fetchDeleteCard: "personal/fetchDeleteCard",
			}),
			handleDeleteCard()
			{
				this.fetchDeleteCard(this.currentDeleteCard.info.ID);
				this.currentDeleteCard.popup = false;
			}
		}
}
</script>

<style lang="scss">
.pay-page
{
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.pay-page__title
{
	font-family: $mainFontBoldNew;
	font-weight: 700;
	font-size: 32px;
	line-height: 75%;
	color: $textColor;
}

.pay-page__cards
{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.pay-page__card
{
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 8px;
	padding: 12px 16px;
	max-width: 440px;
	height: 56px;

	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: $textColor;

	background-color: $greyBackground;
}

.pay-page__card-info
{
	display: flex;
	align-items: center;
	gap: 12px;
}

.pay-page__card-icon
{
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;
	width: 50px;
	height: 32px;
	background-color: $greyLightTextLight;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.pay-page__card-trash
{cursor: pointer;}

.pay-page__delete-popup .confirmation-popup__content .confirmation-popup__title,
.pay-page__delete-popup .confirmation-popup__content .confirmation-popup__subtitle
{
	text-align: left;
}

.pay-page__delete-popup .confirmation-popup
{
	padding: 32px 40px;
	width: 460px;

	@media (max-width: 990px)
	{
		width: auto;
	}
}
</style>
